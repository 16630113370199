
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import WhatHeader from './WhatHeader'
import FutureSection from './FutureSection'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
}));

const WhatSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id='what-it-is'>
      <WhatHeader />
      <FutureSection />
    </section>
  );
};

export default memo(WhatSection);