
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import useCommonStyles from '@styles/use-styles'
import { BAND_WIDE_ICON_IMAGE_PATH } from '@utils/constants/image-paths'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 100,
    paddingBottom: '25vw',
    backgroundColor: theme.palette.background.primary
  },
  title: {
    fontSize: 60,
    fontWeight: 'bold',
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  description: {
    fontSize: 20,
    lineHeight: 2,
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  bandImage: {
    position: 'absolute',
    bottom: '-25vw',
    width: '100%',
    objectFit: 'contain'
  },
}));

const WhatHeader = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <div className={classes.root}>
      <div className={commonClasses.containerWidth}>
        <Typography
          variant='h1'
          align='center'
          className={classes.title}
        >
          THIS IS NOT A SMARTWATCH.
        </Typography>
        <Typography
          align='center'
          className={classes.description}
        >
          Equipped with functions that are designed for certain
          purposes and scenarios, the Experiwear Smartband is
          focused on specific business use cases and as a result -
          deliver a great user experience while creating differentiation
          for the brand.
        </Typography>
      </div>
      <img
        className={classes.bandImage}
        src={BAND_WIDE_ICON_IMAGE_PATH}
        alt='band-wide' />
    </div>
  );
};

export default memo(WhatHeader);