
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

import FeatureItem from './FeatureItem'
import { WHITE_BAND_ICON_IMAGE_PATH } from '@utils/constants/image-paths'
import FEATURES from '@utils/constants/features'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(12, 0, 8)
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    },
  },
  header: {
    fontSize: 60,
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    color: theme.custom.palette.blue,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bandImage: {
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(4)
  }
}));

const Features = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <section className={classes.root} id='features'>
      <Grid container className={clsx(classes.container, commonClasses.containerWidth)}>
        <Grid item sm={12} md={6}>
          <Typography variant='h1' className={classes.header}>
            FEATURES.
          </Typography>
          {
            FEATURES.map((feature, index) => (
              <FeatureItem
                key={index}
                feature={feature}
              />
            ))
          }
        </Grid>
        <Grid item sm={12} md={6} className={classes.imageContainer}>
          <img
            className={classes.bandImage}
            src={WHITE_BAND_ICON_IMAGE_PATH}
            alt='white-band' />
        </Grid>
      </Grid>
    </section>
  );
};

export default memo(Features);