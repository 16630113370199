
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import BRANDS from '@utils/constants/brands'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: theme.spacing(8),
    justifyItems: 'center',
    alignItems: 'center',
    margin: theme.spacing(6.5, 0),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  brandItem: {
    width: '100%',
    height: 62,
    maxWidth: 236,
    objectFit: 'contain'
  }
}));

const Brands = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <section className={classes.root}>
      <div className={clsx(classes.container, commonClasses.containerWidth)}>
        {
          BRANDS.map((brand, index) =>
            <a key={index} href={brand.HREF} target='_blank' rel='noreferrer'>
              <img
                className={classes.brandItem}
                src={brand.IMAGE}
                alt='brand' />
            </a>
          )
        }
      </div>
    </section>
  );
};

export default memo(Brands);