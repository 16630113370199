
import NORDIC_LOGO from '@images/brands/nordic-logo.png';
import ESTIMOTE_LOGO from '@images/brands/estimote-logo.png';
import IMAGINE_X_LOGO from '@images/brands/imx-logo.png';
import VOX_LOGO from '@images/brands/vox-logo.png';

const BRANDS = [
  {
    IMAGE: NORDIC_LOGO,
    HREF: 'https://www.nordicsemi.com/'
  },
  {
    IMAGE: ESTIMOTE_LOGO,
    HREF: 'https://estimote.com/'
  },
  {
    IMAGE: IMAGINE_X_LOGO,
    HREF: 'https://imaginexconsulting.com/'
  },
  {
    IMAGE: VOX_LOGO,
    HREF: 'https://voxsync.com/'
  },
]

export default BRANDS