
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

import ConnectForm from './ConnectForm'
import { BLUE_BAND_ICON_IMAGE_PATH } from '@utils/constants/image-paths'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 1000,
    overflow: 'hidden',
    padding: theme.spacing(12, 0),
    backgroundColor: theme.custom.palette.lightBlue
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 60,
    fontWeight: 'bold',
    lineHeight: 1.2,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  description: {
    fontSize: 20,
    lineHeight: 2,
    marginBottom: theme.spacing(7.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
  },
  bandImage: {
    width: '100%',
    maxWidth: '50vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    },
  }
}));

const Connect = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <section className={classes.root} id='connect'>
      <Grid container spacing={4} className={classes.container}>
        <Grid item sm={12} md={6} className={classes.textContainer}>
          <div className={clsx(classes.textInnerContainer, commonClasses.halfContainerWidth)}>
            <Typography variant='h1' className={classes.title}>
              CONNECT.
            </Typography>
            <Typography variant='h5' className={classes.description}>
              Interested to learn more about the Experiwear Smartband?
              Drop us a note using the form below and we will get back to you.
            </Typography>
            <ConnectForm />
          </div>
        </Grid>
        <Grid item sm={12} md={6} className={classes.imageContainer}>
          <img
            className={classes.bandImage}
            src={BLUE_BAND_ICON_IMAGE_PATH}
            alt='blue-band' />
        </Grid>
      </Grid>
    </section>
  );
};

export default memo(Connect);