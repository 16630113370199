
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4)
  },
  icon: {
    width: 30,
    height: 40,
    objectFit: 'contain',
    marginRight: theme.spacing(2.5)
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.custom.palette.blue,
    margin: theme.spacing(0.75, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    }
  },
  description: {
    fontSize: 20,
    lineHeight: 2,
    color: theme.custom.palette.blue,
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    }
  }
}));

const FeatureItem = ({
  feature
}) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer}>
      <img
        className={classes.icon}
        src={feature.IMAGE}
        alt='feature-icon'
      />
      <div>
        <Typography className={classes.title}>
          {feature.TITLE}
        </Typography>
        <Typography className={classes.description}>
          {feature.DESCRIPTION}
        </Typography>
      </div>
    </div>
  );
};

export default memo(FeatureItem);