
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'

import ContainedButton from '@components/UI/Buttons/ContainedButton'
import OutlinedButton from '@components/UI/Buttons/OutlinedButton'
import {
  ARM_ICON_IMAGE_PATH,
  HERO_SECTION_BACKGROUND_IMAGE_PATH,
} from '@utils/constants/image-paths'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 770,
    backgroundImage: `url(${HERO_SECTION_BACKGROUND_IMAGE_PATH})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    paddingTop: theme.spacing(8.5),
  },
  title: {
    fontSize: 60,
    fontWeight: 'bold',
    lineHeight: 1.2,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  description: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
  },
  note: {
    fontSize: 20,
    lineHeight: 2,
    marginBottom: theme.spacing(7.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  learnMore: {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2.5),
    }
  },
  armContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
  },
  armImage: {
    zIndex: 1,
    width: '100%',
    maxWidth: 450,
    position: 'relative',
    animation: '$fadeInUp 2s none',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
  '@keyframes fadeInUp': {
    '0%': { top: 300 },
    '100%': { top: 0 }
  }
}));

const HeroSection = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <section className={classes.root}>
      <Grid container className={commonClasses.containerWidth}>
        <Grid item sm={12} md={6} lg={7} className={classes.textContainer}>
          <Typography variant='h1' className={classes.title}>
            THE FUTURE OF FRICTIONLESS EXPERIENCES.
          </Typography>
          <Typography variant='h5' className={classes.description}>
            Experiwear line of smartband products was created to deliver frictionless
            experiences that drive business and venue value while creating memorable
            user experiences.
          </Typography>
          <Typography variant='h5' className={classes.note}>
            Click below to learn more and get in-touch with us to find out how
            Experiwear can help you.
          </Typography>
          <div className={classes.buttonContainer}>
            <OutlinedButton href='#what-it-is' className={classes.learnMore}>
              Learn More
            </OutlinedButton>
            <ContainedButton href='#connect'>
              Contact Us
            </ContainedButton>
          </div>
        </Grid>
        <Grid item sm={12} md={6} lg={5} className={classes.armContainer}>
          <img
            className={classes.armImage}
            src={ARM_ICON_IMAGE_PATH}
            alt='home-arm' />
        </Grid>
      </Grid>
    </section>
  );
};

export default memo(HeroSection);