import React from 'react';

import Layout from '@Layout';
import Home from '@containers/Home';

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
);

export default IndexPage;
