
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

import ContainedButton from '@components/UI/Buttons/ContainedButton'
import OutlinedButton from '@components/UI/Buttons/OutlinedButton'
import {
  EXPO_BAND_BACKGROUND_IMAGE_PATH,
  FAN_BAND_BACKGROUND_IMAGE_PATH
} from '@utils/constants/image-paths'
import BANDS from '@utils/constants/bands'
import useBandNav from '@utils/hooks/useBandNav'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 370,
    width: '100%'
  },
  textContainer: {
    display: 'flex',
    padding: theme.spacing(5, 0),
    backgroundColor: theme.custom.palette.lightBlue
  },
  textInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  description: {
    fontSize: 18,
    lineHeight: 2,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  leftButton: {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2.5),
    }
  }
}));

const BandSection = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { band, onBand } = useBandNav();

  const bandHandler = (value) => () => {
    onBand(value)
  }

  return (
    <section className={classes.root} id='band'>
      <Grid container>
        <Grid item sm={12} md={6}
          className={classes.imageContainer}
          style={{
            backgroundImage: `url(${band === BANDS.EXPO
              ? EXPO_BAND_BACKGROUND_IMAGE_PATH
              : FAN_BAND_BACKGROUND_IMAGE_PATH
              })`
          }}
        />
        <Grid item sm={12} md={6} className={classes.textContainer}>
          <div className={clsx(classes.textInnerContainer, commonClasses.halfContainerWidth)}>
            <div className={classes.buttonContainer}>
              {
                band === BANDS.EXPO
                  ? (
                    <>
                      <ContainedButton
                        onClick={bandHandler(BANDS.EXPO)}
                        className={classes.leftButton}
                      >
                        FOR EXPOS
                      </ContainedButton>
                      <OutlinedButton onClick={bandHandler(BANDS.FAN)}>
                        FOR SPORTS
                      </OutlinedButton>
                    </>
                  ) : (
                    <>
                      <OutlinedButton
                        onClick={bandHandler(BANDS.EXPO)}
                        className={classes.leftButton}
                      >
                        FOR EXPOS
                      </OutlinedButton>
                      <ContainedButton onClick={bandHandler(BANDS.FAN)}>
                        FOR SPORTS
                      </ContainedButton>
                    </>
                  )
              }
            </div>
            <Typography variant='h1' className={classes.title}>
              {band === BANDS.EXPO ? 'EXPOBAND' : 'FANBAND'}
            </Typography>
            <Typography variant='h5' className={classes.description}>
              {
                band === BANDS.EXPO
                  ? `Designed specifically for exhibitions, expos, and shows, EXPOBAND provides 
                  your visitors with a frictionless experience like virtual tickets, virtual
                  queuing, tap payments, virtual business card exchanges, and much more.
                  While at the same time allowing for a multitude of sponsorship opportunities
                  via alerts, reminders, draws, band advertising, and much more.`
                  : `Designed specifically for sports venues and stadiums, FANBAND provides your 
                  fans with a frictionless experience like virtual tickets, virtual queuing, 
                  tap payments, coordinated light shows, fan voting, and much more. While at 
                  the same time allowing for a multitude of sponsorship opportunities via alerts, 
                  reminders, band advertising, and much more.`
              }
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default memo(BandSection);