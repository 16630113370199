
import React, { memo } from 'react';

import HeroSection from './HeroSection'
import Brands from './Brands'
import BandSection from './BandSection'
import WhatSection from './WhatSection'
import Features from './Features'
import Connect from './Connect'

const Home = () => (
  <>
    <HeroSection />
    <Brands />
    <BandSection />
    <WhatSection />
    <Features />
    <Connect />
  </>
);

export default memo(Home);
