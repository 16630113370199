
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import useCommonStyles from '@styles/use-styles'
import { BAND_ANIMATION_ICON_IMAGE_PATH } from '@utils/constants/image-paths'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '25vw',
    paddingBottom: 100,
    backgroundColor: theme.custom.palette.grey
  },
  futureContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  welcome: {
    fontSize: 60,
    fontWeight: 'bold',
    marginBottom: 135,
    color: theme.custom.palette.blue,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      marginBottom: 65,
    }
  },
  bandAnimation: {
    width: '100%',
    maxWidth: 505,
    objectFit: 'contain'
  }
}));

const FutureSection = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.futureContainer, commonClasses.containerWidth)}>
        <Typography
          variant='h1'
          align='center'
          className={classes.welcome}
        >
          WELCOME TO THE FUTURE.
        </Typography>
        <img
          className={classes.bandAnimation}
          src={BAND_ANIMATION_ICON_IMAGE_PATH}
          alt='band-animation' />
      </div>
    </div>
  );
};

export default memo(FutureSection);