
import LIGHTNING_ICON from '@images/features/lightning.png';
import SKIP_ICON from '@images/features/transit-skip.png';
import MAP_ICON from '@images/features/map.png';
import CONTACT_LESS_ICON from '@images/features/contact-less.png';
import ALERT_ICON from '@images/features/alert.png';

const FEATURES = [
  {
    IMAGE: LIGHTNING_ICON,
    TITLE: 'FAST ADMISSION',
    DESCRIPTION: `Load virtual tickets onto the smartband and tap to gain access quickly. 
    No fumbling with paper tickets or phones.`
  },
  {
    IMAGE: SKIP_ICON,
    TITLE: 'SKIP THE QUEUE',
    DESCRIPTION: `Use the smartband to tap and reserve your place in line at concession 
    stands or exhibition booths. No waiting around needed.`
  },
  {
    IMAGE: MAP_ICON,
    TITLE: 'QUICK CHECK-INS',
    DESCRIPTION: `Tap the smartband to check-in at specific locations like photo booths 
    in stadiums or at exhibitor booths at exhibitions.`
  },
  {
    IMAGE: CONTACT_LESS_ICON,
    TITLE: 'TAP TO PAY',
    DESCRIPTION: `Attach a payment card to the smartband using the app and pay for purchases 
    by just tapping the smartband.`
  },
  {
    IMAGE: ALERT_ICON,
    TITLE: 'REAL-TIME ALERTS',
    DESCRIPTION: `Featuring 4 bright LED lights, vibration, and speaker module that can flash, 
    play audio, and vibrate when alerts are received.`
  },
]

export default FEATURES