
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import * as connectAPI from '@services/api-connect'
import ContainedButton from '@components/UI/Buttons/ContainedButton'
import OutlinedButton from '@components/UI/Buttons/OutlinedButton'
import FansTextField from '@components/UI/FansTextField'
import {
  NAME_VALID,
  EMAIL_VALID,
  QUESTION_VALID
} from '@utils/constants/validations'
import MESSAGES from '@utils/constants/messages'
import { showErrorToast, showSuccessToast } from '@utils/helpers/toast';
import getConnectErrorMessage from '@utils/errors/getConnectErrorMessage'

const schema = yup.object().shape({
  fullName: NAME_VALID,
  email: EMAIL_VALID,
  question: QUESTION_VALID
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  input: {
    marginBottom: theme.spacing(2.5)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  learnMore: {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2.5),
    }
  }
}));

const ConnectForm = () => {
  const classes = useStyles();

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const params = {
        subject: 'Experiwear Support Ticket',
        email: data.email,
        fullName: data.fullName,
        question: data.question
      }

      await connectAPI.connect(params);
      showSuccessToast(MESSAGES.CONNECT_SUCCESS)
    } catch (error) {
      if (error.response) {
        const { data: { code } } = error.response;
        showErrorToast(getConnectErrorMessage(code))
      }
    }
    resetHandler();
  };

  const resetHandler = () => {
    setValue('fullName', '')
    setValue('email', '')
    setValue('question', '')
  }

  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        as={<FansTextField />}
        required
        name='fullName'
        placeholder='Full Name'
        error={errors.fullName?.message}
        className={classes.input}
        control={control}
        defaultValue=''
      />
      <Controller
        as={<FansTextField />}
        required
        name='email'
        type='email'
        placeholder='Email Address'
        error={errors.email?.message}
        className={classes.input}
        control={control}
        defaultValue=''
      />
      <Controller
        as={<FansTextField />}
        name='question'
        placeholder='Question'
        multiline
        required
        rows={8}
        error={errors.question?.message}
        className={classes.input}
        control={control}
        defaultValue=''
      />
      <div className={classes.buttonContainer}>
        <OutlinedButton
          className={classes.learnMore}
          onClick={resetHandler}
        >
          RESET
        </OutlinedButton>
        <ContainedButton type='submit'>
          SUBMIT
        </ContainedButton>
      </div>
    </form>
  );
};

export default memo(ConnectForm);